import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  types: [],
  is_posted: false,
}
const DebtTypesSlice = createSlice({
  name: 'DebtTypes',
  initialState: initialState,
  reducers: {
    handlePost: (state, action) => {
      state.is_posted = action.payload
    },
    showTypes: (state, action) => {
      state.types = action.payload
    },
    addTypes: (state, action) => {
      state.types.push(action.payload)
    },
    updateTypes: (state, action) => {
      const updateIndex = state.types.findIndex(
        (el) => el.id === action.payload.id
      )
      state.types[updateIndex] = action.payload
    },
    deleteTypes: (state, action) => {
      state.types = state.types.filter((el) => el.id !== action.payload)
    },
  },
})
export const { handlePost, showTypes, addTypes, updateTypes, deleteTypes } =
  DebtTypesSlice.actions
export default DebtTypesSlice.reducer
