import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  packages: [],
  profile: {},
  licence: {},
  isOrder: null,
}

const PackagesSlice = createSlice({
  name: 'packagesSlice',
  initialState: initialState,
  reducers: {
    showPackages: (state, action) => {
      state.packages = action.payload
    },
    showProfile: (state, action) => {
      state.profile = action.payload
    },
    showLicenceUser: (state, action) => {
      state.licence = action.payload
    },
    postOrder: (state, action) => {
      state.isOrder = action.payload
    },
    postPackage: (state, action) => {
      state.profile = action.payload
    },
  },
})

export const { showPackages, showProfile, showLicenceUser, postOrder,postPackage } =
  PackagesSlice.actions

export default PackagesSlice.reducer
