import axios from 'axios'
import { takeEvery, put, call, all } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { StaffPaymentTypes } from './types'
import toast from 'react-hot-toast'
import { showStaffPayment, showStaff, showAccount } from './slice'

function* showStaffPaymentHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/staff/payment?page=${page}`)
    )
    yield put(showStaffPayment(response.data.data.Records))
  } catch (error) {}
}
function* showStaffHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/staffs?page=${page}`)
    )
    const filterResponse = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(showStaff(filterResponse))
  } catch (error) {
    yield put(showStaff([]))
  }
}

function* deleteStaffPaymentHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/staff/payment`, {
        data: { id: payload.id },
      })
    )
    yield showStaffPaymentHandler({ page: page })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addStaffPaymentHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/staff/payment`, payload)
    )
    yield showStaffPaymentHandler({ page: page })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateStaffPaymentHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/staff/payment`, payload)
    )
    yield showStaffPaymentHandler({ page: page })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* showAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/accounts`, payload)
    )
    const filterResponse = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(showAccount(filterResponse))
  } catch (error) {
    yield put(showAccount([]))
  }
}

export function* StaffPaymentSagas() {
  yield all([
    takeEvery(StaffPaymentTypes.SHOW_STAFF, showStaffHandler),
    takeEvery(StaffPaymentTypes.SHOW_STAFF_PAYMENT, showStaffPaymentHandler),
    takeEvery(StaffPaymentTypes.ADD_STAFF_PAYMENT, addStaffPaymentHandler),
    takeEvery(
      StaffPaymentTypes.DELETE_STAFF_PAYMENT,
      deleteStaffPaymentHandler
    ),
    takeEvery(StaffPaymentTypes.SHOW_ACCOUNT, showAccountHandler),
    takeEvery(
      StaffPaymentTypes.UPDATE_STAFF_PAYMENT,
      updateStaffPaymentHandler
    ),
  ])
}
