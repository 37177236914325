import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    subUsers:[],
    selectedSubUser:{
      id: 0,
      name: "",
      email: "",
      surname: "",
      phone: "",
      exec_accounting:2,
      exec_additional_features:2,
      exec_debit:2,
      exec_definitions:2,
      exec_personnel_operations:2,
      exec_resident_and_apartments:2,
      exec_settings:2,
      exec_sms:2,
      show_accounting:2,
      send_sms:2,
      show_additional_features:2,
      show_debit:2,
      show_definitions:2,
      show_personnel_operations:2,
      show_reports:2,
      show_resident_and_apartments:2,
      show_settings:2,
      show_sms:2
    }
}

const SubUserSlice = createSlice({
    name:"subUsers",
    initialState:initialState,
    reducers:{
        showSubUser:(state,action)=>{
            state.subUsers = action.payload
        },
        addSubUser:(state,action)=>{
            state.subUsers.push(action.payload)
        },
        selectedSubUser:(state,action)=>{
            state.selectedSubUser = action.payload
        },
        updateSubUser:(state,action)=> {
            const update = state.subUsers.findIndex(
                (data)=>data.id === action.payload.id
            )
            state.subUsers[update] = action.payload
        },
        deleteSubUser:(state,action)=>{
            state.subUsers = state.subUsers.filter(
                (data)=>data.id !== action.payload
            )
        }
    }
})

export const {
    showSubUser,
    addSubUser,
    updateSubUser,
    deleteSubUser,
    selectedSubUser
} = SubUserSlice.actions
export default SubUserSlice.reducer