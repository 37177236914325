import axios from 'axios'
import toast from 'react-hot-toast'
import { application } from '../../../../../redux/store'
import { takeLatest, all, call, put } from 'redux-saga/effects'
import {
  postOrder,
  postPackage,
  showLicenceUser,
  showPackages,
  showProfile,
} from './slice'
import { PackageTypes } from './types'

function* showPackagesHandler({ payload }) {
  try {
    const response = yield call(() => axios.get(`${application.api}/packages`))
    yield put(showPackages(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* showProfileHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/me`))
    yield put(showProfile(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showLicenceHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/info`))
    yield put(showLicenceUser(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* buyOrderHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/order`, payload.data)
    )
    yield put(postOrder(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* buyPackageHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/package/sms`, {
        id: payload.id,
      })
    )
    yield put(postPackage(response.data.data))
    yield showProfileHandler()
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* buyLicenceHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/licence`, {
        package_id: payload.id,
        apartment_count: parseInt(payload.apartmentCount),
        month: payload.month,
      })
    )
    yield put(postPackage(response.data.data))
    yield showProfileHandler()
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
export function* PackageSagas() {
  yield all([
    takeLatest(PackageTypes.GET_PACKAGES, showPackagesHandler),
    takeLatest(PackageTypes.GET_PROFILE_INFO, showProfileHandler),
    takeLatest(PackageTypes.GET_INFO_LICENCSE, showLicenceHandler),
    takeLatest(PackageTypes.POST_ORDER, buyOrderHandler),
    takeLatest(PackageTypes.POST_PACKAGE, buyPackageHandler),
    takeLatest(PackageTypes.POST_LICENCE, buyLicenceHandler),
  ])
}
