import axios from 'axios'
import {takeEvery,put ,all ,call} from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { PasswordTypes } from './types'
import toast from 'react-hot-toast'
import { updatePassword } from './slice'


function* updatePasswordHandler({payload}) {
    try {
        const response = yield call(()=>axios.put(`${application.api}/change/password`,{
            old_password:payload.old_password,
            new_password:payload.new_password,
        }))
        yield put(updatePassword(payload))
        toast.success(response.data.description)
    } catch (error) {
        toast.error(error.response.data.description)
    }
}

function* getPasswordHandler() {
    try {
        const response = yield call(()=>axios.get(`${application.api}/change/password`))
        const password_data = {
            old_password:response.data.data.old_password,
        }
        yield put(updatePassword(password_data))
    } catch (error) {
        toast.error(error.response.data.description)
    }
}

export function* PasswordSagas() {
    yield all(
        [
            takeEvery(PasswordTypes.UPDATE_PASSWORD,updatePasswordHandler),
            takeEvery(PasswordTypes.GET_PASSWORD,getPasswordHandler)
        ]
    )
}