import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";

const initialState = {
    cashBook:{},
    cashBookTotal:{},
    // totalAmount:0,
    is_filter_load:false,
}

const CashBookSlice = createSlice({
    name:"cashBookSlice",
    initialState:initialState,
    reducers: {
        filterLoad:(state,action)=>{
            state.is_filter_load = action.payload
        },
        showCashBook: (state, action) => {
            action.payload.map((item) => (
                item.start_date = moment(item.date).format("DD.MM.YYYY"),
                item.end_date = moment(item.date).format("DD.MM.YYYY")
            ))
            state.cashBook = action.payload
        },
        showCashBookTotal: (state, action) => {
            state.cashBookTotal = action.payload
        }
        
    }
})

export const { showCashBook, showCashBookTotal,filterLoad } = CashBookSlice.actions

export default CashBookSlice.reducer