import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isRegister: false,
  isRegisterValid: false,
  isRegisterCodeValid: '',
}
const RegisterSlice = createSlice({
  name: 'registerSlice',
  initialState: initialState,
  reducers: {
    registerSuccess: (state) => {
      state.isRegister = true
    },
    registerError: (state) => {
      state.isRegister = false
    },
    registerFinish: (state) => {
      state.isRegister = false
    },
    validRegister: (state, action) => {
      state.isRegisterValid = action.payload
    },
    validRegisterCode: (state, action) => {
      state.isRegisterCodeValid = action.payload
    },
  },
})
export const {
  registerSuccess,
  registerError,
  registerFinish,
  validRegister,
  validRegisterCode,
} = RegisterSlice.actions
export default RegisterSlice.reducer
