import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import toast from 'react-hot-toast'
import { application } from '../../../../../../redux/store'
import { addTodo, showAllTodoList, showTodoList } from './slice'
import { TodoListTypes } from './types'

function* showTodoListHandler({ date }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/todo?date=${date}`)
    )
    yield put(showTodoList(response.data.data))
  } catch (error) {}
}

function* showAllTodoListHandler({ page, date }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/todo?is_month=true&date=${date}`)
    )
    yield put(showAllTodoList(response.data.data))
  } catch (error) {}
}

function* addTodoHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/todo`, payload)
    )
    yield put(
      addTodo({
        content: response.data.data.content,
        id: response.data.data.id,
        subject: response.data.data.subject,
        date: response.data.data.Date,
        done: 0,
      })
    )
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateTodoListHandler({ payload, date }) {
  try {
    const payloadId = payload.map((data) => data.id)
    const response = yield call(() =>
      axios.put(`${application.api}/todo`, {
        id: payloadId,
        done: true,
        date: date,
      })
    )
    yield showTodoListHandler({ page: 1, date })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteTodoListHandler({ payload, date, page }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/todo`, { data: { id: payload } })
    )
    yield showTodoListHandler({ date })
    yield showAllTodoListHandler({ date })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateSingleTodoHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/todo`, {
        id: [payload.id],
        done: payload.done,
        date: payload.date,
        content: payload.content,
        subject: payload.subject,
      })
    )
    yield showTodoListHandler({ date: payload.date })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* TodoListsSagas() {
  yield all([
    takeEvery(TodoListTypes.SHOW_TODO_LIST, showTodoListHandler),
    takeEvery(TodoListTypes.SHOW_ALL_TODO, showAllTodoListHandler),
    takeEvery(TodoListTypes.DELETE_TODO, deleteTodoListHandler),
    takeEvery(TodoListTypes.UPDATE_TODO, updateTodoListHandler),
    takeEvery(TodoListTypes.ADD_TODO, addTodoHandler),
    takeEvery(TodoListTypes.UPDATE_SINGLE, updateSingleTodoHandler),
  ])
}
