import axios from 'axios'
import toast from 'react-hot-toast'
import { application } from '../../../../../../redux/store'
import { takeLatest, all, call, put } from 'redux-saga/effects'
import { showDashboardTables } from './slice'
import { DashboardTablesTypes } from './types'

function* getDashboard() {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/dashboard`, {
        params: {
          start_date: '01.01.2000',
          end_date: '31.12.2030',
        },
      })
    )
    yield put(showDashboardTables(response.data.data))
  } catch (error) {
    if (error.response.data.description === 'missing or malformed jwt') {
      localStorage.removeItem('auth')
      navigate('/login')
    }
  }
}

export function* DashboardTablesSagas() {
  yield all([
    takeLatest(DashboardTablesTypes.GET_DASHBOARD_TABLES, getDashboard),
  ])
}
