import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    interestReport:[],
    additionalData:{},
}

const InterestReportSlice = createSlice({
  name: "interestReport",
  initialState: initialState,
  reducers: {
    showInterestReport: (state, action) => {
      state.interestReport = action.payload;
    },
  },
});

export const { showInterestReport } = InterestReportSlice.actions;

export default InterestReportSlice.reducer;
