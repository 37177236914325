import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
    expenses: [],
    is_posted:false
}
const AccountExpenseSlice = createSlice({
    name: "collection",
    initialState: initialState,
    reducers: {
        handlePost:(state,action)=>{
            state.is_posted = action.payload
        },
        showExpense: (state, action) => {
            action.payload.map((item) => (
                item.date = moment(item.date).format("DD.MM.YYYY"),
                item.bill_date = moment(item.bill_date).format("DD.MM.YYYY")
            ))
            state.expenses = action.payload
        },
        addExpense: (state, action) => {
            state.expenses.push(action.payload)
        },
        updateExpense: (state, action) => {
            const paymentIndex = state.expenses.findIndex((el) => el.id === action.payload.id)
            state.expenses[paymentIndex] = {
                amount: action.payload.amount,
                account: action.payload.account,
                account_id: action.payload.account_id,
                date: action.payload.date,
                bill_date: action.payload.bill_date,
                description: action.payload.description,
                id: action.payload.id,
                accounting_type: action.payload.accounting_type,
                accounting_type_id: action.payload.accounting_type_id
            }

        },
        deleteExpense: (state, action) => {
            state.expenses = state.expenses.filter((el) => el.id !== action.payload)
        },
    }
})
export const {  
    handlePost,
    showExpense, 
    addExpense, 
    updateExpense,
    deleteExpense, 
} = AccountExpenseSlice.actions

export default AccountExpenseSlice.reducer