import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settings: [],
  templates: [],
};

const UserSettingsSlice = createSlice({
  name: "usersettings",
  initialState,
  reducers: {
    showSettings: (state, action) => {
      state.settings = action.payload;
    },
    updateSettings: (state, action) => {
      state.settings = action.payload;
    },
    showTemplateSettings: (state, action) => {
      state.templates = action.payload;
    },
    updateTemplateSettings: (state, action) => {
      const SettingsIndex = state.templates.findIndex(
        (el) => el.id === action.payload.id
      );
      state.templates[SettingsIndex] = action.payload;
    },
  },
});

export const {
  showSettings,
  updateSettings,
  showTemplateSettings,
  updateTemplateSettings,
} = UserSettingsSlice.actions;

export default UserSettingsSlice.reducer;
