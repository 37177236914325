import axios from 'axios'
import { AnnouncementsTypes } from './types'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import {
  showAnnouncements,
  showUserAnno,
  showDeleted,
  showupdateAnno,
  setActiveStatus,
  setPassiveStatus,
} from './slice'

function* showAnnouncementsHandler({ payload }) {
  try {
    console.log(payload)
    const response = yield call(() =>
      axios.get(`${application.api}/user/anno`, {
        params: {
          page: payload.page,
        },
      })
    )
    yield put(showAnnouncements(response.data.data))
  } catch (error) {}
}
function* UserAnno({ payload }) {
  const formData = new FormData()
  formData.append('File', payload.file)
  formData.append('content', payload.content)
  formData.append('title', payload.title)
  formData.append('user_id', payload.user_id)
  formData.append('is_exist_file', payload.is_exist_file)
  formData.append('passive', 1)
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/user/anno`, formData)
    )
    yield showAnnouncementsHandler({ payload })
  } catch (error) {
    console.error('showUserAnno Error:', error)
  }
}
function* deletedAnno({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/user/anno/${payload.id}`)
    )
    yield put(showDeleted(payload.id))
    yield showAnnouncementsHandler({ payload })
  } catch (error) {
    console.error('deletedAnno Error:', error)
  }
}
function* updateAnno({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/user/anno/${payload.id}`, {
        content: payload.content,
        title: payload.title,
        user_id: payload.user_id,
        passive: payload.passive,
      })
    )
    yield showAnnouncementsHandler({ payload})
    yield put(showupdateAnno({ id: payload.id, passive: payload.passive }))
  } catch (error) {}
}
function* activateAnno({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/user/anno/${payload.id}`, {
        content: payload.content,
        title: payload.title,
        user_id: payload.user_id,
        passive: 1,
      })
    )
    yield showAnnouncementsHandler({ payload })
  } catch (error) {
    console.error('activateAnno Error:', error)
  }
}

function* deactivateAnno({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/user/anno/${payload.id}`, {
        content: payload.content,
        title: payload.title,
        user_id: payload.user_id,
        passive: 0,
      })
    )
    yield showAnnouncementsHandler({ payload })
  } catch (error) {
    console.error('deactivateAnno Error:', error)
  }
}
export function* announcementsSagas() {
  yield all([
    takeEvery(AnnouncementsTypes.SHOW_ANNOUNCEMENTS, showAnnouncementsHandler),
    takeEvery(AnnouncementsTypes.SHOW_GETUSERANNO, UserAnno),
    takeEvery(AnnouncementsTypes.SHOW_DELETEDANNO, deletedAnno),
    takeEvery(AnnouncementsTypes.SHOW_UPDATEANNO, updateAnno),
    takeEvery(AnnouncementsTypes.ACTIVATE_ANNO, activateAnno),
    takeEvery(AnnouncementsTypes.DEACTIVATE_ANNO, deactivateAnno),
  ])
}
