import axios from "axios";
import toast from "react-hot-toast";
import { application } from "../../../../../../redux/store";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { filterLoad, showIncomeTable, showTotalIncome } from "./slice";
import { IncomeTableTypes } from "./types";

function* getIncomeTable({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/payment/report?=${payload.page}`, {
        accountID: payload.data.accountID,
        accountingTypeID: payload.data.accounting_type_id,
        start_date: payload.data.start_date,
        end_date: payload.data.end_date,
      })
    );

    if (response.data.data.Records.length > 0) {
      yield put(showIncomeTable(response.data.data.Records));
      yield put(
        showTotalIncome(response.data.data.AdditionalData.totalPayment)
      );
    } else {
      toast.error("Veri Bulunamadı!");
    }
    yield put(filterLoad(false))
    // yield put(showTotalAmount(response.data.data.AdditionalData))
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

export function* IncomeTableSagas() {
  yield all([takeLatest(IncomeTableTypes.GET_INC_TABLE, getIncomeTable),
    takeLatest(IncomeTableTypes.GET_INC_TABLE, filterLoadHandler)]);
}
