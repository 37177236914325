import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { AccountTypes } from './types'
import toast from 'react-hot-toast'
import { addAccount, deleteAccount, showAccount, updateAccount } from './slice'

function* showAccountHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/accounts?page=${page}`)
    )
    yield put(showAccount(response.data.data.Records))
  } catch (error) {}
}

function* addAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/account`, payload.values)
    )
    yield put(addAccount(response.data.data))
    yield showAccountHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/account`, payload)
    )
    yield put(updateAccount(response.data.data))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/account`, {
        data: {
          id: payload.id,
        },
      })
    )
    yield put(deleteAccount(payload.id))
    yield showAccountHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* AccountSagas() {
  yield all([
    takeEvery(AccountTypes.SHOW_ACCOUNT, showAccountHandler),
    takeEvery(AccountTypes.ADD_ACCOUNT, addAccountHandler),
    takeEvery(AccountTypes.UPDATE_ACCOUNT, updateAccountHandler),
    takeEvery(AccountTypes.DELETE_ACCOUNT, deleteAccountHandler),
  ])
}
