import axios from "axios";
/*eslint-disable */
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { DebtReportTypes } from "./types";
import { showDebtReportDetail, showDebtReports } from "./slice";
import toast from "react-hot-toast";
function* showDebtRecordsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/multi/debts?page=${payload.page}`)
    );
    yield put(showDebtReports(response.data.data.Records));
  } catch (error) {
    yield put(showDebtReports(error.response.data.data.Records));
  }
}
function* showDebtRecordDetailHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/multi/debt?id=${payload.id}&page=${payload.page}`
      )
    );
    yield put(showDebtReportDetail(response.data.data.Records));
  } catch (error) {
    yield put(showDebtReportDetail(error.response.data.data));
  }
}
function* deleteDebtRecordsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/multi/debt`, {
        data: {
          multi_debt_id: payload.id,
          set_advance_payment:payload.IsAdvancePayment,
          is_payment_delete :payload.IsPaymentDelete
        },
      })
    );
    toast.success(response.data.description);
    yield showDebtRecordsHandler({ payload });
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
export function* DebtRecordsSagas() {
  yield all([
    takeEvery(DebtReportTypes.SHOW_DEBT_REPORTS, showDebtRecordsHandler),
    takeEvery(
      DebtReportTypes.SHOW_DEBT_REPORT_DETAIL,
      showDebtRecordDetailHandler
    ),
    takeEvery(DebtReportTypes.DELETE_DEBT_REPORT, deleteDebtRecordsHandler),
  ]);
}
