import axios from "axios";
import toast from "react-hot-toast";
import { application } from "../../../../../../redux/store";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { showInterestReport, showAdditionalData } from "./slice";
import { InterestReportTypes } from "./types";

function* getInterestReport({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/interest/reports?page=${page}`)
    );
    yield put(showInterestReport(response.data.data.Records));
  } catch (error) {}
}

export function* InterestReportSagas() {
  yield all([
    takeLatest(InterestReportTypes.GET_INTEREST_REPORT, getInterestReport),
  ]);
}
