import { createSlice } from "@reduxjs/toolkit";

const initialState = { subAccs: [] };

const SubAccountsSlice = createSlice({
  name: "subAccs",
  initialState: initialState,
  reducers: {
    showSubAccounts: (state, action) => {
      state.subAccs = action.payload;
    },
    addSubAccounts: (state, action) => {
      state.subAccs.push(action.payload);
    },
    deleteSubAccounts: (state, action) => {
      const delSubs = state.subAccs.filter((el) => el.id !== action.payload.id);
      state.subAccs = delSubs;
    },
    updateSubAccounts: (state, action) => {
      const updateIndex = state.subAccs.findIndex(
        (el) => el.id === action.payload.id
      );
      state.subAccs[updateIndex] = action.payload;
    },
  },
});
export const {
  showSubAccounts,
  addSubAccounts,
  deleteSubAccounts,
  updateSubAccounts,
} = SubAccountsSlice.actions;
export default SubAccountsSlice.reducer;
