import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { BuildingTypes } from './types'
import toast from 'react-hot-toast'
import {
  filterLoad,
  handlePost,
  showBuilding,
  updateBuilding,
  updateSmsCode,
} from './slice'

function* showBuildingHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/building?key=${payload.key}&page=${payload.page}`
      )
    )
    yield put(showBuilding(response.data.data.Records))
    yield put(filterLoad(false))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

function* newBuildingHandler({ payload, page }) {
  payload.apartment_limit = parseInt(payload.apartment_limit)
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/building`, payload)
    )
    // yield put(addBuilding(response.data.data))
    const newPayload = {
      page: page,
      key: '',
    }
    yield showBuildingHandler({ payload: newPayload })
    yield put(handlePost(true))
    toast.success(response.data.description)
  } catch (error) {
    yield put(handlePost(false))
    toast.error(error.response.data.description)
  }
}

function* updateBuildingHandler({ payload }) {
  payload.apartment_limit = parseInt(payload.apartment_limit)
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/building`, payload)
    )
    yield put(updateBuilding(payload))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteSmsCodeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/building/delete/request?id=${payload.building_id}`
      )
    )
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteBuildinghHandler({ id, page, code, payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/building`, {
        data: {
          id: id,
          code: code,
        },
      })
    )
    yield showBuildingHandler({ payload })
    yield put(updateSmsCode(true))
    toast.success(response.data.description)
  } catch (error) {
    yield put(updateSmsCode(false))
    toast.error(error.response.data.description)
  }
}
export function* BuildingSagas() {
  yield all([
    takeEvery(BuildingTypes.SHOW_BUILDING, showBuildingHandler),
    takeEvery(BuildingTypes.SHOW_BUILDING, filterLoadHandler),
    takeEvery(BuildingTypes.ADD_BUILDING, newBuildingHandler),
    takeEvery(BuildingTypes.DELETE_BUILDING, deleteBuildinghHandler),
    takeEvery(BuildingTypes.UPDATE_BUILDING, updateBuildingHandler),
    takeEvery(BuildingTypes.SEND_BUILD_DELETE_CODE, deleteSmsCodeHandler),
  ])
}
