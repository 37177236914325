import axios from "axios";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { SubAccountsTypes } from "./types";
import {
  showSubAccounts,
  addSubAccounts,
  deleteSubAccounts,
  updateSubAccounts,
} from "./slice";
import toast from "react-hot-toast";

function* showSubAccountsHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/sub/user?page=${page}`)
    );
    yield put(showSubAccounts(response.data.data.Records));
  } catch (error) { }
}

function* addSubAccountsHandler({ payload }) {
  payload.data.phone = payload.data.phone.replaceAll(" ", "");
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/sub/user`, payload.data)
    );
    yield put(addSubAccounts(response.data.data));
    yield showSubAccountsHandler(payload);
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteSubAccountsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/sub/user`, {
        data: {
          id: payload.data,
        },
      })
    );
    yield put(deleteSubAccounts(payload.data));
    yield showSubAccountsHandler(payload);
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* updateSubAccountsHandler({ payload }) {
  if (payload.password === "") {
    payload.password = null;
  }
  payload.phone = payload.phone.replaceAll(" ", "");
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/sub/user`, payload)
    );
    yield put(updateSubAccounts(payload));
    toast.success(response.data.description);
  } catch (error) { }
}

/* eslint-disable */
export function* SubAccountsSagas() {
  yield all([
    takeEvery(SubAccountsTypes.SHOW_SUB_ACCOUNTS, showSubAccountsHandler),
    takeEvery(SubAccountsTypes.ADD_SUB_ACCOUNTS, addSubAccountsHandler),
    takeEvery(SubAccountsTypes.UPDATE_SUB_ACCOUNTS, updateSubAccountsHandler),
    takeEvery(SubAccountsTypes.DELETE_SUB_ACCOUNTS, deleteSubAccountsHandler),
  ]);
}
