import axios from 'axios'
import { takeEvery, put, call, all } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { numberReplace } from '../../../../../functions/Number/numberReplace'
import toast from 'react-hot-toast'
import { RegisterTypes } from './types'
import {
  registerError,
  registerFinish,
  registerSuccess,
  validRegisterCode,
  validRegister,
} from './slice'

// function* registerSendCodeToUser(phone) {}

function* registerHandler({ payload }) {
  try {
    yield call(() =>
      axios.post(`${application.api}/register`, {
        phone: numberReplace(payload.phone),
        assoc_name: payload.assoc_name,
        email: payload.email,
        name: payload.name,
        password: payload.password,
        surname: payload.surname,
      })
    )
    yield put(validRegister(true))
  } catch (error) {
    toast.error(error.response.data.description)
    yield put(registerError())
  }
}

function* registerSendCodeToUser({ payload }) {
  try {
    yield call(() =>
      axios.get(`${application.api}/register/send/code`, {
        params: {
          phone: numberReplace(payload.phone),
        },
      })
    )
    yield put(validRegisterCode(true))
  } catch (error) {
    toast.error(error.response.data.description)
    yield put(registerError())
  }
}

function* registerControlCode({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/register/control/code`, {
        params: {
          phone: numberReplace(payload.phone),
          code: payload.code,
        },
      })
    )
    yield put(registerSuccess())
  } catch (error) {
    toast.error(error.response.data.description)
    yield put(validRegisterCode('Not Valid'))
    yield put(registerError())
  }
}

function* finishRegisterHandler() {
  yield put(registerFinish())
  yield put(validRegister(false))
}
function* resetRegisterPage() {
  yield put(validRegister(false))
}

export function* RegisterSagas() {
  yield all([
    takeEvery(RegisterTypes.RQ_REGISTER, registerHandler),
    takeEvery(RegisterTypes.REGISTER_FINISH, finishRegisterHandler),
    takeEvery(RegisterTypes.REGISTER_CONTROL_CODE, registerControlCode),
    takeEvery(RegisterTypes.REGISTER_SEND_CODE_TO_USER, registerSendCodeToUser),
    takeEvery(RegisterTypes.RESET_REGISTER_PAGE, resetRegisterPage),
  ])
}
