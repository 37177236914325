import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staff_payment: [],
  staffs: [],
  accounts: [],
};

const StaffPaymentSlice = createSlice({
  name: "StaffPayment",
  initialState: initialState,
  reducers: {
    showStaffPayment: (state, action) => {
      state.staff_payment = action.payload;
    },
    showStaff: (state, action) => {
      state.staffs = action.payload;
    },
    showAccount: (state, action) => {
      state.accounts = action.payload;
    },
  },
});
export const {
  showStaffPayment,
  addStaffPayment,
  deleteStaffPayment,
  showStaff,
  showAccount,
} = StaffPaymentSlice.actions;

export default StaffPaymentSlice.reducer;
