import axios from "axios";
import toast from "react-hot-toast";
import { application } from "../../../../../../redux/store";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { showBusinessLedger, showBusinessLedgerTotal,filterLoad } from "./slice";
import { BusinessLedgerTypes } from "./types";

function* getBusinessLedger({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/pay/exp/report?page=${payload.page}`, {
        start_date: payload.data?.start_date,
        end_date: payload.data?.end_date,
      })
    );

    yield put(showBusinessLedger(response.data.data.Records));
    yield put(showBusinessLedgerTotal(response.data.data.AdditionalData));
    yield put(filterLoad(false))
} catch (error) {
    toast.error(error);
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

export function* BusinessLedgerSagas() {
  yield all([
    takeLatest(BusinessLedgerTypes.GET_BUSINESS_LEDGER, getBusinessLedger),
    takeLatest(BusinessLedgerTypes.GET_BUSINESS_LEDGER, filterLoadHandler),
    takeLatest(
      BusinessLedgerTypes.GET_BUSINESS_LEDGER_TOTAL,
      getBusinessLedger
    ),
  ]);
}
