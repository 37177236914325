import axios from 'axios'
import { takeEvery,put,all,call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { SubUserTypes } from './types'
import toast from 'react-hot-toast'
import { 
  showSubUser,
  addSubUser,
  deleteSubUser,
  updateSubUser, 
  selectedSubUser
} from './slice'

function* showSubUserHandler({payload}) {
    try {
        const response = yield call(()=>
            axios.get(`${application.api}/sub/user`,{
                params:{
                    page: payload.page
                }
            })
        )
        yield put(showSubUser(response.data.data.Records))
    } catch (error) {
        throw error
    }
}

function* addSubUserHandler({payload}) {
    try {
        const response = yield call(()=>
        axios.post(`${application.api}/sub/user`, {
            name: payload.name,
            surname: payload.surname,
            email: payload.email,
            phone: payload.phone,
            password: payload.password,
            exec_accounting: 2,
            exec_additional_features:2,
            exec_debit:2,
            exec_definitions:2,
            exec_personnel_operations:2,
            exec_resident_and_apartments:2,
            exec_settings:2,
            exec_sms:2,
            show_accounting:2,
            send_sms:2,
            show_additional_features:2,
            show_debit:2,
            show_definitions:2,
            show_personnel_operations:2,
            show_reports:2,
            show_resident_and_apartments:2,
            show_settings:2,
            show_sms:2
        })
        )
        const {
            name,
            assoc_name,
            phone,
            email,
            id,
            main_user_id,
            surname,
        } = response.data.data
        yield put(addSubUser({
            name,
            assoc_name,
            id,
            email,
            phone,
            surname,
            main_user_id,
        }))
        toast.success(response.data.description)
    } catch (error) {
        toast.error(error.response.data.description)
    }
}

function* selectedSubUserHandler({payload}) {
    try {
        const response = yield call(()=>
            axios.get(`${application.api}/sub/user/${payload}`)
        )
        yield put(selectedSubUser({
            id: response.data.data.id,
      name:  response.data.data.name,
      email:  response.data.data.email,
      surname:  response.data.data.surname,
      phone:  response.data.data.phone,
      exec_accounting: response.data.data.sub_user_privileges.exec_accounting,
      exec_additional_features:response.data.data.sub_user_privileges.exec_additional_features,
      exec_debit:response.data.data.sub_user_privileges.exec_debit,
      exec_definitions:response.data.data.sub_user_privileges.exec_definitions,
      exec_personnel_operations:response.data.data.sub_user_privileges.exec_personnel_operations,
      exec_resident_and_apartments:response.data.data.sub_user_privileges.exec_resident_and_apartments,
      exec_settings:response.data.data.sub_user_privileges.exec_settings,
      exec_sms:response.data.data.sub_user_privileges.exec_sms,
      show_accounting:response.data.data.sub_user_privileges.show_accounting,
      send_sms:response.data.data.sub_user_privileges.send_sms,
      show_additional_features:response.data.data.sub_user_privileges.show_additional_features,
      show_debit:response.data.data.sub_user_privileges.show_debit,
      show_definitions:response.data.data.sub_user_privileges.show_definitions,
      show_personnel_operations:response.data.data.sub_user_privileges.show_personnel_operations,
      show_reports:response.data.data.sub_user_privileges.show_reports,
      show_resident_and_apartments:response.data.data.sub_user_privileges.show_resident_and_apartments,
      show_settings:response.data.data.sub_user_privileges.show_settings,
      show_sms:response.data.data.sub_user_privileges.show_sms
        }))
    } catch (error) {
        
    }
}

function* updateSubUserHandler({payload}) {
   try {
        const response = yield call(()=>
        axios.put(`${application.api}/sub/user`,{
            id:payload.id,
            name:payload.name,
            surname:payload.surname,
            phone:payload.phone.replace(/\s/g, ''),
            email:payload.email,
            exec_accounting: payload.exec_accounting,
            exec_additional_features:payload.exec_additional_features,
            exec_debit:payload.exec_debit,
            exec_definitions:payload.exec_definitions,
            exec_personnel_operations:payload.exec_personnel_operations,
            exec_resident_and_apartments:payload.exec_resident_and_apartments,
            exec_settings:payload.exec_settings,
            exec_sms:payload.exec_sms,
            show_accounting:payload.show_accounting,
            send_sms:payload.send_sms,
            show_additional_features:payload.show_additional_features,
            show_debit:payload.show_debit,
            show_definitions:payload.show_definitions,
            show_personnel_operations:payload.show_personnel_operations,
            show_reports:payload.show_reports,
            show_resident_and_apartments:payload.show_resident_and_apartments,
            show_settings:payload.show_settings,
            show_sms:payload.show_sms
        })
        )
        yield put(updateSubUser(response.data.data))
        yield showSubUserHandler()
        toast.success(response.data.description)
    } catch (error) {
        toast.error(error.response.data.description)
    }
}

function* deleteSubUserHandler({payload}) {
    try {
        const response = yield call(() =>
        axios.delete(`${application.api}/sub/user`, {
          data: {
            id: payload.id,
          },
         })
        )
        yield put(deleteSubUser(payload.id))
        yield showSubUserHandler(payload)
        toast.success(response.data.description)
    } catch (error) {
        toast.error(error.response.data.description)
    }
}

export function* SubUserSagas() {
    yield all([
        takeEvery(
            SubUserTypes.ADD_SUB_USER,addSubUserHandler
        ),
        takeEvery(
            SubUserTypes.SHOW_SUB_USER,showSubUserHandler
        ),
        takeEvery(
            SubUserTypes.DELETE_SUB_USER,deleteSubUserHandler
        ),
        takeEvery(
            SubUserTypes.UPDATE_SUB_USER,updateSubUserHandler
        ),
        takeEvery(
            SubUserTypes.SELECTED_SUB_USER,selectedSubUserHandler
        )
    ])
}