import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  apartmentsSingleDebt: [],
  debts: [],
  debts_error: [],
  is_filter_load: false,
}
const SingleDebtSlice = createSlice({
  name: 'ApartmentSingleDebtSlice',
  initialState: initialState,
  reducers: {
    filterLoad: (state, action) => {
      state.is_filter_load = action.payload
    },
    showApartment: (state, action) => {
      state.apartmentsSingleDebt = action.payload
    },
    addSingleDebt: (state, action) => {
      state.debts_error = action.payload.debts_error
    },
  },
})
export const { showApartment, addSingleDebt, filterLoad } =
  SingleDebtSlice.actions
export default SingleDebtSlice.reducer
