import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  apartmentsGroupDebt: [],
  debts_error: [],
  debtGroups: [],
  is_filter_load: false,
}
const GroupDebtSlice = createSlice({
  name: 'GroupDebtSlice',
  initialState: initialState,
  reducers: {
    filterLoad: (state, action) => {
      state.is_filter_load = action.payload
    },
    showApartment: (state, action) => {
      state.apartmentsGroupDebt = action.payload
    },
    addGroupDebt: (state, action) => {
      state.debts_error = action.payload
    },
    showDebtGroups: (state, action) => {
      state.debtGroups = action.payload
    },
  },
})
export const { showApartment, addGroupDebt, showDebtGroups, filterLoad } =
  GroupDebtSlice.actions
export default GroupDebtSlice.reducer
