import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../redux/store'
import { UserTypes } from './types'
import toast from 'react-hot-toast'
import { updateUser } from './slice'
import { numberReplace } from '../../../../functions/Number/numberReplace'

function* updateUserHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/user`, {
        name: payload.name,
        surname: payload.surname,
        email: payload.email,
        assoc_name: payload.assoc_name,
        national_id: payload.nationalID,
        phone: numberReplace(payload.phone),
      })
    )
    yield put(updateUser(payload))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* getUserHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/me`))
    const user_data = {
      id: response.data.data.id,
      name: response.data.data.name,
      surname: response.data.data.surname,
      email: response.data.data.email,
      phone: response.data.data.phone,
      NationalID: response.data.data.national_id,
      is_branch:response.data.data.is_branch,
      branchs:response.data.data.branchs,
      assoc_name: response.data.data.assoc_name,
      apartment_count:response.data.data.apartment_count,
      apartment_limit:response.data.data.apartment_limit,
      logo:
        response.data.data.logo === ''
          ? '/image/home.jfif'
          : response.data.data.logo,
    }
    yield put(updateUser(user_data))
  } catch (error) {
    if (error.response.data.description === 'missing or malformed jwt') {
      toast.error('Giriş Yapınız!')
    } else {
      toast.error(error.response.data.description)
    }
  }
}

function* updateLogoHandler({ payload }) {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    const response = yield call(() =>
      axios.put(`${application.api}/logo`, payload, config)
    )
    yield getUserHandler()
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* removeLogoHandler({}) {
  try {
    const response = yield call(() => axios.delete(`${application.api}/logo`))
    yield getUserHandler()
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* resetRequestHandler({}) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/reset/request`)
    )
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* resetCodeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/reset`, {
        params: {
          code: payload.code.toString(),
        },
      })
    )
    console.log(response.data)
    toast.success(response.data.description)
  } catch (error) {
    console.log(error.response)
    toast.error(error.response.data.description)
  }
}

export function* UserSagas() {
  yield all([
    takeEvery(UserTypes.UPDATE_USER, updateUserHandler),
    takeEvery(UserTypes.GET_USER, getUserHandler),
    takeEvery(UserTypes.UPDATE_LOGO, updateLogoHandler),
    takeEvery(UserTypes.REMOVE_LOGO, removeLogoHandler),
    takeEvery(UserTypes.RESET_USER, resetRequestHandler),
    takeEvery(UserTypes.RESET_CODE_VERIFICATION, resetCodeHandler),
  ])
}
