import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";

const initialState = {
    advanceRecords:{},
    is_filter_load:false
}

const AdvanceRecordsSlice = createSlice({
    name:"advanceRecordsSlice",
    initialState:initialState,
    reducers: {
        filterLoad:(state,action)=>{
            state.is_filter_load = action.payload
        },
        showAdvanceRecords: (state, action) => {
            action.payload.map((item) => (
                item.start_date = moment(item.date).format("DD.MM.YYYY"),
                item.end_date = moment(item.date).format("DD.MM.YYYY")
            ))
            state.advanceRecords = action.payload
        },
    }
})

export const { showAdvanceRecords,filterLoad } = AdvanceRecordsSlice.actions

export default AdvanceRecordsSlice.reducer