export const AddinitionalFeaturesTypes = {
    SHOW_GUEST: "SHOW_GUEST",
    ADD_GUEST: "ADD_GUEST",
    UPDATE_GUEST: "UPDATE_GUEST",
    DELETE_GUEST: "DELETE_GUEST",
    SHOW_GUEST_FILTER: "SHOW_GUEST_FILTER",
    SHOW_DOCUMENT: "SHOW_DOCUMENT",
    ADD_DOCUMENT: "ADD_DOCUMENT",
    DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
    UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
    DELETE_DOCUMENT:'DELETE_DOCUMENT'
};
