// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import LoginSlice from '../views/pages/authentication/Login/store/slice'
import RegisterSlice from '../views/pages/authentication/Register/store/slice'
import ResidentSlice from '../views/apps/residents/residentOpt/store/slice'
import ApartmentSlice from '../views/apps/residents/apartmentOpt/store/slice'
import BuildingSlice from '../views/apps/residents/buildingOpt/store/slice'
import AccountSlice from '../views/apps/definitions/register/store/slice'
import AttendanceSheeSlice from '../views/apps/residents/otherOpt/attendanceShee/store/slice'
import DebtTypesSlice from '../views/apps/definitions/accountingItems/store/slice'
import SearchSelectSlice from './searchServices/slice'
import DefDebtGroupSlice from '../views/apps/definitions/debtGroups/store/slice'
import { combineReducers } from 'redux'
import CurrentAccountSlice from '../views/apps/definitions/currentAccounts/store/slice'
import DefinitionsSlice from '../views/apps/definitions/store/slice'
import DebtExemptionSlice from '../views/apps/definitions/debtExemption/store/slice'
import SubAccountsSlice from '../views/apps/definitions/subAccounts/store/slice'
import AddinitionalFeaturesSlice from '../views/apps/additionalFeatures/store/slice'
import ReportsSlice from '../views/apps/reports/store/slice'
import DefDueGroupSlice from '../views/apps/definitions/dueGroups/store/slice'
import FilterSlice from './filterSercives/slice'
import SingleDebtSlice from '../views/apps/debit/singleDebt/store/slice'
import BuildingDebtSlice from '../views/apps/debit/buildingAutoDebt/store/slice'
import LandDebtSlice from '../views/apps/debit/landDebt/store/slice'
import AreaDebtSlice from '../views/apps/debit/area/store/slice'
import GroupDebtSlice from '../views/apps/debit/groupDebt/store/slice'
import DebtReportsSlice from '../views/apps/debit/debtReports/store/slice'
import EqualDebtSlice from '../views/apps/debit/equalDebt/store/slice'
import PersonDebtSlice from '../views/apps/debit/personDebt/store/slice'
import UserSlice from '../views/apps/profile/store/slice'
import SubUserSlice from '../views/apps/additionalFeatures/subUser/store/slice'
import AccountCurrentSlice from '../views/apps/accounting/currentRecords/store/slice'
import AccountPaymentSlice from '../views/apps/accounting/incomeRecords/store/slice'
import AccountCollectionSlice from '../views/apps/accounting/collection/store/slice'
import AccountExpenseSlice from '../views/apps/accounting/expenseRecords/store/slice'
import SmsTemplateSlice from '../views/apps/smsOpt/smsTemplates/store/slice'
import SmsReportSlice from '../views/apps/smsOpt/smsReports/store/slice'
import PollSlice from '../views/apps/smsOpt/pollOpt/store/slice'
import VoteSlice from '../views/pages/vote/store/slice'
import StaffPaymentSlice from '../views/apps/employee/payment/store/slice'
import LogsSlice from '../views/apps/logsStore/slice'
import toDebtSmsSlice from '../views/apps/smsOpt/collectiveSms/toDebt/store/slice'
import ToNonMembersSmsSlice from '../views/apps/smsOpt/collectiveSms/toNonMembers/store/slice'
import toResidentSmsSlice from '../views/apps/smsOpt/collectiveSms/toResidents/store/slice'
import ToGroupSmsSlice from '../views/apps/smsOpt/collectiveSms/toGroup/store/slice'
import toBuildingsSmsSlice from '../views/apps/smsOpt/collectiveSms/toBuildings/store/slice'
import SmsJobsSlice from '../views/apps/smsOpt/smsJobs/store/slice'
import SmsNonMembersSlice from '../views/apps/smsOpt/nonMemberSms/store/slice'
import IncomeSlice from '../views/apps/reports/tables/incomeTable/store/slice'
import ExpenseSlice from '../views/apps/reports/tables/expenseTable/store/slice'
import CashBookSlice from '../views/apps/reports/tables/cashBook/store/slice'
import BusinessLedgerSlice from '../views/apps/reports/tables/businessLedger/store/slice'
import AccountActivitiesSlice from '../views/apps/reports/tables/accountActivities/store/slice'
import AdvanceRecordsSlice from '../views/apps/reports/tables/advanceRecords/store/slice'
import TransferRecordsSlice from '../views/apps/reports/tables/transferRecords/store/slice'
import BalanceReportSlice from '../views/apps/reports/tables/balanceSheet/store/slice'
import InterestReportSlice from '../views/apps/reports/tables/totalInterestList/store/slice'
import InterestSlice from '../views/apps/definitions/interestSettings/store/slice'
import BranchesSlice from '../views/pages/authentication/Branches/store/slice'
import VirmanSlice from '../views/apps/accounting/transfer/store/slice'
import PackagesSlice from '../views/apps/settings/balance/store/slice'
import UserSettingsSlice from '../views/apps/settings/user-settings/store/slice'
import ForgotPasswordSlice from '../views/pages/authentication/ForgotPassword/store/slice'
import TodoListsSlice from '../views/dashboard/ecommerce/containers/todo/store/slice'
import DashboardTablesSlice from '../views/dashboard/ecommerce/views/Charts/store/slice'
import StaffPermissionSlice from '../views/apps/employee/permission/store/slice'
import PollSmsSlice from '../views/apps/smsOpt/pollSms/store/slice'
import ExcelDebtSlice from '../views/apps/debit/excelDebt/store/slice'
import LicenseSlice from '../views/apps/settings/licence/store/slice'
import ReceiptSlice from '../views/apps/accounting/receipt/store/slice'
import LoginAdminSlice from '../views/pages/authentication/LoginAdmin/store/slice'
import TicketSlice from '../views/apps/support/ticket/store/slice'
import UserSearchSlice from '../@core/layouts/components/navbar/store/slice'
import announcementsSlice from '../views/apps/additionalFeatures/announcement/store/slice'
import ConversationSlice from '../views/apps/support/user-saves/store/slice'

const rootReducer = combineReducers({
  DefDebtGroupSlice,
  DebtTypesSlice,
  SearchSelectSlice,
  AttendanceSheeSlice,
  AccountSlice,
  BuildingSlice,
  ApartmentSlice,
  ResidentSlice,
  RegisterSlice,
  LoginSlice,
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  CurrentAccountSlice,
  DefinitionsSlice,
  DebtExemptionSlice,
  SubAccountsSlice,
  AddinitionalFeaturesSlice,
  ReportsSlice,
  DefDueGroupSlice,
  FilterSlice,
  SingleDebtSlice,
  BuildingDebtSlice,
  LandDebtSlice,
  AreaDebtSlice,
  GroupDebtSlice,
  DebtReportsSlice,
  EqualDebtSlice,
  PersonDebtSlice,
  UserSlice,
  SubUserSlice,
  AccountCurrentSlice,
  AccountPaymentSlice,
  AccountCollectionSlice,
  AccountExpenseSlice,
  SmsTemplateSlice,
  SmsReportSlice,
  PollSlice,
  VoteSlice,
  StaffPaymentSlice,
  LogsSlice,
  toDebtSmsSlice,
  SmsJobsSlice,
  IncomeSlice,
  ExpenseSlice,
  CashBookSlice,
  BusinessLedgerSlice,
  AccountActivitiesSlice,
  AdvanceRecordsSlice,
  TransferRecordsSlice,
  BalanceReportSlice,
  InterestReportSlice,
  InterestSlice,
  BranchesSlice,
  toResidentSmsSlice,
  toBuildingsSmsSlice,
  VirmanSlice,
  SmsNonMembersSlice,
  ToNonMembersSmsSlice,
  PackagesSlice,
  UserSettingsSlice,
  ToGroupSmsSlice,
  ForgotPasswordSlice,
  TodoListsSlice,
  DashboardTablesSlice,
  StaffPermissionSlice,
  PollSmsSlice,
  ExcelDebtSlice,
  LicenseSlice,
  ReceiptSlice,
  LoginAdminSlice,
  TicketSlice,
  UserSearchSlice,
  announcementsSlice,
  ConversationSlice,
  UserSearchSlice,
})

export default rootReducer
