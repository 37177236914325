// ** Redux Imports
import reducers from './rootReducer'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from './rootSaga'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { useEffect } from 'react'
import axios from 'axios'
import layout from './layout'

const saga = createSagaMiddleware()

export const store = configureStore({
  reducer: reducers,
  middleware: [saga, thunk],
})
saga.run(rootSaga)

export const application = {
  api: 'https://api.vayonet.com',
}

// export const store = configureStore({
//   reducer: reducers,
//   middleware: getDefaultMiddleware => {
//     return getDefaultMiddleware({
//       serializableCheck: false
//     })
//   }
// })
