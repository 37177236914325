import axios from "axios";
import toast from "react-hot-toast";
import { application } from "../../../../../../redux/store";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { filterLoad, showExpenseTable, showTotalExpense } from "./slice";
import { ExpenseTableTypes } from "./types";

function* getExpenseTable({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/expense/report?page=${payload.page}`, {
        account_id: payload.data.accountID,
        // accountingTypeID: 1, //geçici value
        start_date: payload.data.start_date,
        end_date: payload.data.end_date,
      })
    );

    if (response.data.data.Records.length > 0) {
      yield put(showExpenseTable(response.data.data.Records));
      yield put(
        showTotalExpense(response.data.data.AdditionalData.totalExpense)
      );
    } else {
      toast.error("Veri Bulunamadı!");
    }
  yield put(filterLoad(false))
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

export function* ExpenseTableSagas() {
  yield all([takeLatest(ExpenseTableTypes.GET_EXP_TABLE, getExpenseTable),takeLatest(ExpenseTableTypes.GET_EXP_TABLE, filterLoadHandler)]);
}
