import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { DebtExemptionTypes } from './types'
import {
  showApartmentByBuilding,
  sendDebtExemption,
  showExemptionByApartment,
  deleteExemptionByApartment,
  addExemptionByApartment,
  filterLoad,
} from './slice'
import toast from 'react-hot-toast'

function* showApartmentByBuildingHandler({ page, id }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/apartments?page=${page}`, {
        building_id: id?.length > 0 ? id : null,
      })
    )
    yield put(showApartmentByBuilding(response.data.data))
    yield put(filterLoad(false))
  } catch (error) {}
}

function* showExemptionByApartmentHandler({ id }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/debt/exemptions/apartment?apartment_id=${id}`
      )
    )
    yield put(showExemptionByApartment(response.data.data))
    yield put(filterLoad(false))
  } catch (error) {}
}
function* filterLoadHandler() {
  yield put(filterLoad(true))
}
function* sendDebtExemptionHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/exemption`, payload)
    )
    // yield put(addExemptionByApartment(response.data.data));
    const newResponse = {
      id: payload.apartment_id,
    }
    yield filterLoadHandler()
    yield showExemptionByApartmentHandler(newResponse)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* sendDebtExemptionDeleteHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/debt/exemption`, {
        data: {
          id: payload.id,
        },
      })
    )
    //ilerde burayı daha optimize hale getirebiliriz
    yield put(deleteExemptionByApartment(payload.apartment_id))
    const newResponse = {
      id: payload.apartment_id,
    }
    yield filterLoadHandler()
    yield showExemptionByApartmentHandler(newResponse)

    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* DebtExemptionSagas() {
  yield all([
    takeEvery(
      DebtExemptionTypes.SHOW_APARTMENTS,
      showApartmentByBuildingHandler
    ),
    takeEvery(DebtExemptionTypes.SEND_DEBT_EXEMPTION, sendDebtExemptionHandler),
    takeEvery(
      DebtExemptionTypes.SHOW_EXEMPTION_BY_APARTMENT,
      showExemptionByApartmentHandler
    ),
    takeEvery(DebtExemptionTypes.SHOW_APARTMENTS, filterLoadHandler),
    takeEvery(
      DebtExemptionTypes.SEND_DEBT_EXEMPTION_DELETE,
      sendDebtExemptionDeleteHandler
    ),
  ])
}
