import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { DebtGroupTypes } from './types'
import toast from 'react-hot-toast'
import {
  addDebtGroup,
  deleteDebtGroup,
  handlePost,
  showDebtGroup,
  updateDebtGroup,
} from './slice'

function* showDebtGroupHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/groups?page=${page}&type=1`)
    )
    yield put(showDebtGroup(response.data.data.Records))
  } catch (error) {}
}

function* addDebtGroupHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/group`, payload.values)
    )
    yield put(addDebtGroup(response.data.data))
    yield showDebtGroupHandler(payload)
    yield put(handlePost(true))
    toast.success(response.data.description)
  } catch (error) {
    yield put(handlePost(false))
    toast.error(error.response.data.description)
  }
}

function* updateDebtGroupHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/group`, payload)
    )
    yield put(updateDebtGroup(payload))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteDebtGroupHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/group`, {
        data: {
          id: payload.id,
        },
      })
    )
    yield put(deleteDebtGroup(payload.id))
    yield showDebtGroupHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* DefDebtGroupSagas() {
  yield all([
    takeEvery(DebtGroupTypes.SHOW_DEBT_GROUP, showDebtGroupHandler),
    takeEvery(DebtGroupTypes.ADD_DEBT_GROUP, addDebtGroupHandler),
    takeEvery(DebtGroupTypes.UPDATE_DEBT_GROUP, updateDebtGroupHandler),
    takeEvery(DebtGroupTypes.DELETE_DEBT_GROUP, deleteDebtGroupHandler),
  ])
}
