import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  phone: '',
  password: '',
  isLogin: false,
  user: [],
  loginButton: false,
  loginError: [],
  licenseError: false,
}
const LoginSlice = createSlice({
  name: 'LoginSlice',
  initialState: initialState,
  reducers: {
    userInformation: (state, action) => {
      state.user = [action.payload]
    },
    loginSuccess: (state) => {
      state.errors = false
      state.isLogin = true
    },
    loginError: (state, action) => {
      state.loginError = [action.payload]
    },
    loginButton: (state, action) => {
      state.loginButton = action.payload
    },
    licenseErr: (state, action) => {
      state.licenseError = action.payload
    },
  },
})
export const {
  loginSuccess,
  loginError,
  userInformation,
  loginButton,
  licenseErr,
} = LoginSlice.actions
export default LoginSlice.reducer
