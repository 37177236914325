import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guests: [],
  documents: [],
};
const AddinitionalFeaturesSlice = createSlice({
  name: "buildings",
  initialState: initialState,
  reducers: {
    showGuest: (state, action) => {
      state.guests = action.payload;
    },
    addGuest: (state, action) => {
      state.guests.push(action.payload);
    },
    updateGuest: (state, action) => {
      const GuestIndex = state.guests.findIndex(
        (el) => el.id === action.payload.id
      );
      state.guests[GuestIndex] = {
        amount: action.payload.amount,
        account: action.payload.account,
        account_id: action.payload.account_id,
        date: action.payload.date,
        description: action.payload.description,
        id: action.payload.id,
        accounting_type: action.payload.accounting_type,
        accounting_type_id: action.payload.accounting_type_id,
      };
    },
    deleteGuest: (state, action) => {
      state.guests = state.guests.filter((el) => el.id !== action.payload);
    },
    showDocument: (state, action) => {
      state.documents = action.payload;
    },
    addDocument: (state, action) => {
      state.documents.push(action.payload);
    },
    deleteDocument: (state, action) => {
      state.documents = state.documents.filter(
        (el) => el.id !== action.payload
      );
    },
  },
});
export const {
  showGuest,
  addGuest,
  updateGuest,
  deleteGuest,
  showDocument,
  addDocument,
  deleteDocument,
} = AddinitionalFeaturesSlice.actions;

export default AddinitionalFeaturesSlice.reducer;
