import axios from "axios";
import toast from "react-hot-toast";
import { application } from "../../../../../../redux/store";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { showCashBook, showCashBookTotal,filterLoad } from "./slice";
import { CashBookTypes } from "./types";

function* getCashBook({ payload }) {
    try {
    const response = yield call(() =>
        axios.post(`${application.api}/pay/exp/report?page=${payload.page}`, {
            accountID: payload.data?.accountID,
            start_date: payload.data?.start_date,
            end_date: payload.data?.end_date,
        })
    );

    yield put(showCashBook(response.data.data.Records));
    yield put(showCashBookTotal(response.data.data.AdditionalData))
  yield put(filterLoad(false))
} catch (error) {
    toast.error(error.response.data.description);
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

export function* CashBookSagas() {
  yield all([
    takeLatest(CashBookTypes.GET_CASHBOOK, getCashBook),
    takeLatest(CashBookTypes.GET_CASHBOOK, filterLoadHandler),
    takeLatest(CashBookTypes.GET_CASHBOOK_TOTAL, getCashBook),
  ]);
}
