import axios from "axios";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../redux/store";
import { DefinitionsTypes } from "./types";
import toast from "react-hot-toast";
import { addStaff, deleteStaff, showStaff, updateStaff, isStaffAdded } from "./slice";
import moment from "moment";
import formatNumber from "../../../../functions/Number/formatNumber";

function* showStaffHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/staffs?page=${page}`)
    );
    yield put(showStaff(response.data.data.Records));
  } catch (error) { }
}

function* newStaffHandler({ payload }) {
  payload.data.phone = payload.data.phone.replaceAll(" ", "");
  payload.data.buildings = payload.buildings.map(
    (building) => building.id
  );
  
  payload.data.start_date = moment(payload.data.start_date).format(
    "DD.MM.YYYY"
  );
  payload.data.national_id = payload.data.national_id.toString();
  payload.data.insurance_no = payload.data.insurance_no.toString();
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/staff`, payload.data)
    );
    // yield put(addStaff(response.data.data));
    yield showStaffHandler(payload);
    toast.success(response.data.description);
    yield put(isStaffAdded(true));
  } catch (error) {
    toast.error(error.response.data.description);
    yield put(isStaffAdded(false));
  }
}

function* updateStaffHandler({ payload }) {
  payload.data.phone = payload.data.phone.replaceAll(" ", "");

  payload.data.start_date = moment(payload.data.start_date).format(
    "DD.MM.YYYY"
  );
  payload.data.national_id = payload.data.national_id.toString();
  payload.data.insurance_no = payload.data.insurance_no.toString();
  payload.data.buildings = payload.data.buildings.map(
    (building) => building.id
  );

  try {
    const response = yield call(() =>
      axios.put(`${application.api}/staff`, payload.data)
    );
    yield put(updateStaff(response.data.data));
    //response tam donmediği için tekrardan istek attırdım
    yield showStaffHandler(payload);
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* deleteStaffHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/staff`, {
        data: {
          id: payload,
        },
      })
    );
    yield put(deleteStaff(payload));
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

export function* DefinationSagas() {
  yield all([
    takeEvery(DefinitionsTypes.SHOW_STAFF, showStaffHandler),
    takeEvery(DefinitionsTypes.ADD_STAFF, newStaffHandler),
    takeEvery(DefinitionsTypes.DELETE_STAFF, deleteStaffHandler),
    takeEvery(DefinitionsTypes.UPDATE_STAFF, updateStaffHandler),
  ]);
}
