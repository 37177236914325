import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apartmentsAreaDebt: [],
  debts_error: [],
  is_filter_load:false
};
const AreaDebtSlice = createSlice({
  name: "AreaDebtSlice",
  initialState: initialState,
  reducers: {
    filterLoad:(state,action)=>{
      state.is_filter_load = action.payload
    },
    showApartment: (state, action) => {
      state.apartmentsAreaDebt = action.payload;
    },
    addLandDebt: (state, action) => {
      // state.debts.push(action.payload);
      state.debts_error = action.payload.debts_error
    },
  },
});
export const { showApartment, addLandDebt ,filterLoad} = AreaDebtSlice.actions;
export default AreaDebtSlice.reducer;
