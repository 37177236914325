import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addExcelSubmit: null,
  debts_error: [],
};
const ExcelDebtSlice = createSlice({
  name: "ExcelDebtSlice",
  initialState: initialState,
  reducers: {
    addExcelDebt: (state, action) => {
      state.addExcelSubmit = action.payload;
    },
    debtErrors: (state, action) => {
      state.debts_error = action.payload;
    },
  },
});
export const { addExcelDebt, debtErrors } = ExcelDebtSlice.actions;
export default ExcelDebtSlice.reducer;
