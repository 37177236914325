import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { AccountCollectionTypes } from './types'
import toast from 'react-hot-toast'
import { filterLoad, showCollection, showPdfCollections } from './slice'
import { application } from '../../../../../redux/store'

function* showCollectionHandler(data) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debts?page=${data.page}`, data.payload)
    )
    yield put(showCollection(response.data.data.Records))
    yield put(filterLoad(false))
  } catch (error) {}
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

function* paymentCollectionHandler(data) {
  const payload = {
    account_id: data.payload.account_id,
    amount: parseFloat(data.payload.amount),
    date: data.payload.date,
    debt_id: data.payload.id,
    description: data.payload.description,
    accounting_type_id: data.payload.accounting_type_id,
  }
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/payment/debt`, payload)
    )
    toast.success(response.data.description)
    const newPayload = {
      page: data.page,
    }
    yield put(showPdfCollections(true))
    yield filterLoadHandler()
    yield showCollectionHandler({ payload: newPayload })
  } catch (error) {
    toast.error(error.response.data.description)
    yield put(showPdfCollections(false))
  }
}
function* deleteCollectionHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/delete/debt/single`, {
        data: {
          id: payload.id,
          set_advance_payment: true,
        },
      })
    )
    //yield put(deleteApartment(id))
    yield showCollectionHandler({
      page: payload.page,
      payload: {
        building_ids: null,
        end_debt: '',
        is_excel: false,
        start_debt: '',
      },
    })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* AccountCollectionSagas() {
  yield all([
    takeEvery(AccountCollectionTypes.SHOW_COLLECTION, showCollectionHandler),
    takeEvery(AccountCollectionTypes.SHOW_COLLECTION, filterLoadHandler),
    takeEvery(
      AccountCollectionTypes.PAYMENT_COLLECTION,
      paymentCollectionHandler
    ),
    takeEvery(
      AccountCollectionTypes.DELETE_COLLECTION,
      deleteCollectionHandler
    ),
  ])
}
