import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { DebtTypes } from './types'
import toast from 'react-hot-toast'
import {
  addTypes,
  deleteTypes,
  handlePost,
  showTypes,
  updateTypes,
} from './slice'

function* showTypeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/accounting/types?page=${payload.page}&type=${payload.type}`
      )
    )
    yield put(showTypes(response.data.data.Records))
  } catch (error) {}
}

function* addTypeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/accounting/type`, {
        name: payload.values.name,
        type: payload.type,
      })
    )
    yield put(addTypes(response.data.data))
    yield showTypeHandler(payload.values)
    yield put(handlePost(true))
    toast.success(response.data.description)
  } catch (error) {
    yield put(handlePost(false))
    toast.error(error.response.data.description)
  }
}

function* updateTypeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/accounting/type`, {
        id: payload.values.id,
        name: payload.values.name,
        type: payload.type,
      })
    )
    yield put(updateTypes(payload.values))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteTypeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/accounting/type`, {
        data: {
          id: payload.id,
        },
      })
    )
    yield put(deleteTypes(payload.id))
    yield showTypeHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* DebtTypesSaga() {
  yield all([
    takeEvery(DebtTypes.SHOW_TYPE, showTypeHandler),
    takeEvery(DebtTypes.ADD_TYPE, addTypeHandler),
    takeEvery(DebtTypes.UPDATE_TYPE, updateTypeHandler),
    takeEvery(DebtTypes.DELETE_TYPE, deleteTypeHandler),
  ])
}
