import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  buildings: [],
  sms_code: false,
  is_filter_load: false,
  is_posted: false,
}
const BuildingSlice = createSlice({
  name: 'buildings',
  initialState: initialState,
  reducers: {
    filterLoad: (state, action) => {
      state.is_filter_load = action.payload
    },
    showBuilding: (state, action) => {
      state.buildings = action.payload
    },
    addBuilding: (state, action) => {
      state.buildings.push(action.payload)
    },
    handlePost: (state, action) => {
      state.is_posted = action.payload
    },
    updateBuilding: (state, action) => {
      const buildingIndex = state.buildings.findIndex(
        (el) => el.id === action.payload.id
      )
      state.buildings[buildingIndex] = action.payload
    },
    deleteBuilding: (state, action) => {
      state.buildings = state.buildings.filter((el) => el.id !== action.payload)
    },
    updateSmsCode: (state, action) => {
      state.sms_code = action.payload
    },
  },
})
export const {
  filterLoad,
  showBuilding,
  addBuilding,
  deleteBuilding,
  updateBuilding,
  showFilterBuilding,
  updateSmsCode,
  handlePost,
} = BuildingSlice.actions

export default BuildingSlice.reducer
