import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apartmentsSingleDebt: [],
  smsPreview: {},
  is_filter_load:false
};
const toResidentSmsSlice = createSlice({
  name: "toDebtSms",
  initialState: initialState,
  reducers: {
    filterLoad:(state,action)=>{
      state.is_filter_load = action.payload
    },
    showResidents: (state, action) => {
      state.residents = action.payload;
    },
    showSmsPreview: (state, action) => {
      state.smsPreview = action.payload;
    },
  },
});
export const { showResidents, showSmsPreview ,showSmsTemplates,filterLoad} = toResidentSmsSlice.actions;
export default toResidentSmsSlice.reducer;
