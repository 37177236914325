import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apartmentsSingleDebt: [],
  buildings: [],
  smsPreview: {}
};
const toBuildingsSmsSlice = createSlice({
  name: "toDebtSms",
  initialState: initialState,
  reducers: {
    showBuildings: (state, action) => {
      state.buildings = action.payload;
    },
    showSmsPreview: (state, action) => {
      state.smsPreview = action.payload;
    },
  },
});
export const { showBuildings, showSmsPreview } = toBuildingsSmsSlice.actions;
export default toBuildingsSmsSlice.reducer;
