import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apartmentsLandDebt: [],
  debts_error: [],
  is_filter_load:false
};
const LandDebtSlice = createSlice({
  name: "LandDebtSlice",
  initialState: initialState,
  reducers: {
    filterLoad:(state,action)=>{
      state.is_filter_load = action.payload
    },
    showApartment: (state, action) => {
      state.apartmentsLandDebt = action.payload;
    },
    addLandDebt: (state, action) => {
      // state.debts.push(action.payload);
      state.debts_error = action.payload.debts_error
    },
  },
});
export const { showApartment, addLandDebt,filterLoad } = LandDebtSlice.actions;
export default LandDebtSlice.reducer;
