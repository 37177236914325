import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../redux/store";
import { ReportsTypes } from "./types";
import { showResidentDebts } from "./slice";
import axios from "axios";

function* showResidentDebtsHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident?page=${page}`)
    );
    yield put(showResidentDebts(response.data.data.Records));
  } catch (error) { }
}

export function* ReportsSagas() {
  // eslint-disable-next-line array-bracket-newline
  yield all([
    yield takeEvery(ReportsTypes.SHOW_RESIDENT_DEBTS, showResidentDebtsHandler),
    // eslint-disable-next-line array-bracket-newline
  ]);
}
