import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    passwords:{}
}

const PasswordSlice = createSlice({
    name:"passwords",
    initialState:initialState,
    reducers:{
        updatePassword:(state,action)=>{
            state.passwords = action.payload
        },
        getPassword:(state,action)=>{
            state.passwords = action.payload
        }
    }
})

export const {updatePassword} = PasswordSlice.actions

export default PasswordSlice.reducer