import axios from 'axios'
import { takeEvery, call, all, put } from 'redux-saga/effects'
import { application } from '../../../redux/store'
import { LogsTypes } from './types'
import { showLogs } from './slice'
import toast from 'react-hot-toast'

function* showLogsHandler({ table, page }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/system/logs?table=${table}&page=${page.page}`
      )
    )
    yield put(showLogs(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* LogsSagas() {
  yield all([takeEvery(LogsTypes.SHOW_LOGS, showLogsHandler)])
}
