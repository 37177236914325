import axios from 'axios'
import { takeEvery, call, all, put } from 'redux-saga/effects'
import { UserSettingTypes } from './types'
import {
  showTemplateSettings,
  showSettings,
  updateTemplateSettings,
  updateSettings,
} from './slice'
import toast from 'react-hot-toast'
import { application } from '../../../../../redux/store'

function* showTemplateSettingsHandler() {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/sms/templates`)
    )
    yield put(showTemplateSettings(response.data.data))
  } catch (error) {}
}

function* updateTemplateSettingsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/sms/templates`, payload)
    )
    yield put(updateTemplateSettings(payload))
    toast.success(response.data.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showSettingsHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/settings`))
    yield put(showSettings(response.data.data))
  } catch (error) {}
}

function* updateSettingsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/settings`, {
        auto_payment_advance_payments: payload.auto_payment_advance_payments,
        birthDay: {
          birth_day: payload.birth_day,
          birth_day_message_template: payload.birth_day_message_template,
        },
        debt: {
          remin_debt: payload.remin_debt,
          remind_debt_with_since_day: payload.remind_debt_with_since_day,
        },
        paymentMessage: {
          payment_message: payload.send_payment_message,
          payment_message_template: payload.payment_message_template,
        },
      })
    )
    yield put(updateSettings(payload))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* UserSettingsSagas() {
  yield all([
    takeEvery(UserSettingTypes.SHOW_USER_SETTINGS, showSettingsHandler),
    takeEvery(
      UserSettingTypes.SHOW_TEMPLATE_SETTINGS,
      showTemplateSettingsHandler
    ),
    takeEvery(UserSettingTypes.UPDATE_USER_SETTINGS, updateSettingsHandler),
    takeEvery(
      UserSettingTypes.UPDATE_TEMPLATE_SETTINGS,
      updateTemplateSettingsHandler
    ),
  ])
}
