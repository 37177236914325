import axios from 'axios'
import { takeEvery, call, all, put } from 'redux-saga/effects'
import { TicketsTypes } from './types'
import { errorTickes, sendTickes, showTicketDetail, showTickets } from './slice'
import toast from 'react-hot-toast'
import { application } from '../../../../../redux/store'

function* sendTicketSmsHandler() {
  try {
    const response = yield call(() => axios.get(`ws://api.vayonet/admin/ws/1`))
    yield put(sendTickes(response))
    yield put(errorTickes(false))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showTicketHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/ticket?page=${payload.page}`)
    )
    yield put(showTickets(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* newTicketHandler({ payload }) {
  try {
    const res = yield call(() =>
      axios.post(`${application.api}/ticket`, payload.data)
    )
    toast.success(res.data.description)
    yield showTicketHandler({ payload: { page: payload.page } })
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showTicketDetailHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/ticket/${payload.id}`)
    )
    yield put(showTicketDetail(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* sendTicketMessageHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/ticket/send/user`, payload.data)
    )
    yield showTicketDetailHandler({ payload: { id: payload.data.ticket_id } })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* TicketSagas() {
  yield all([
    takeEvery(TicketsTypes.SHOW_TICKET, showTicketHandler),
    takeEvery(TicketsTypes.SHOW_TICKET_DETAIL, showTicketDetailHandler),
    takeEvery(TicketsTypes.NEW_TICKET, newTicketHandler),
    takeEvery(TicketsTypes.SEND_TICKET_MESSAGE, sendTicketMessageHandler),
  ])
}
