import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { call, all, put, takeEvery } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { addTemplate, showTemplate, isTemplateCreated } from "./slice";
import { SmsTemplateTypes } from "./type";

function* showSmsTemplateHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/sms/templates?page=${page}`)
    );
    yield put(showTemplate(response.data.data));
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* addSmsTemplateHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/sms/template`, payload)
    );
    // yield put(addTemplate(response.data.data));
    yield showSmsTemplateHandler({ page: 1 });
    toast.success(response.data.description);
    yield put(isTemplateCreated(true))
  } catch (error) {
    toast.error(error.response.data.description);
    yield put(isTemplateCreated(false))
  }
}

function* delSmsTemplateHandler({ id }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/sms/template`, {
        data: {
          id: id
        }
      })
    );
    toast.success(response.data.description);
    yield showSmsTemplateHandler({ page: 1 })
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* updateSmsTemplateHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/sms/template`, payload)
    );
    yield showSmsTemplateHandler({ page: 1 });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
export function* smsTemplateSagas() {
  yield all([
    takeEvery(SmsTemplateTypes.SMS_TEMPLATE_ADD, addSmsTemplateHandler),
    takeEvery(SmsTemplateTypes.SMS_TEMPLATE_SHOW, showSmsTemplateHandler),
    takeEvery(SmsTemplateTypes.SMS_TEMPLATE_DELETE, delSmsTemplateHandler),
    takeEvery(SmsTemplateTypes.SMS_TEMPLATE_UPDATE, updateSmsTemplateHandler),
  ]);
}
