import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";

const initialState = {
    transferRecords:{},
    is_filter_load:false
}

const TransferRecordsSlice = createSlice({
    name:"transferRecordsSlice",
    initialState:initialState,
    reducers: {
        filterLoad:(state,action)=>{
            state.is_filter_load = action.payload
        },
        showTransferRecords: (state, action) => {
            action.payload.map((item) => (
                item.start_date = moment(item.date).format("DD.MM.YYYY"),
                item.end_date = moment(item.date).format("DD.MM.YYYY")
            ))
            state.transferRecords = action.payload
        },
    }
})

export const { showTransferRecords ,filterLoad} = TransferRecordsSlice.actions

export default TransferRecordsSlice.reducer