import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accounts: [],
}
const AccountSlice = createSlice({
  name: 'accounts',
  initialState: initialState,
  reducers: {
    showAccount: (state, action) => {
      state.accounts = action.payload
    },
    addAccount: (state, action) => {
      state.accounts.push(action.payload)
    },
    updateAccount: (state, action) => {
      const updateIndex = state.accounts.findIndex(
        (el) => el.id === action.payload.id
      )
      state.accounts[updateIndex] = action.payload
    },
    deleteAccount: (state, action) => {
      state.accounts = state.accounts.filter((el) => el.id !== action.payload)
    },
  },
})
export const { showAccount, addAccount, updateAccount, deleteAccount } =
  AccountSlice.actions
export default AccountSlice.reducer
