import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  payments: [],
};
const AccountPaymentSlice = createSlice({
  name: "payment",
  initialState: initialState,
  reducers: {
    showPayment: (state, action) => {
      action.payload.map(
        (item) => (item.date = moment(item.date).format("DD.MM.YYYY"))
      );

      state.payments = action.payload;
    },
    addPayment: (state, action) => {
      state.payments.push(action.payload);
    },
    updatePayment: (state, action) => {
      const paymentIndex = state.payments.findIndex(
        (el) => el.id === action.payload.id
      );

      state.payments[paymentIndex] = {
        amount: action.payload.amount,
        account: action.payload.account,
        account_id: action.payload.account_id,
        date: action.payload.date,
        description: action.payload.description,
        id: action.payload.id,
        accounting_type: action.payload.accounting_type,
        accounting_type_id: action.payload.accounting_type_id,
      };
    },
    deletePayment: (state, action) => {
      state.payments = state.payments.filter((el) => el.id !== action.payload);
    },
  },
});
export const { showPayment, addPayment, updatePayment, deletePayment } =
  AccountPaymentSlice.actions;

export default AccountPaymentSlice.reducer;
