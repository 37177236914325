const formatNumber = (newNumber, oldNumber) => {
    const phoneNuber = [];
    if (newNumber.charAt() === "0" || newNumber.charAt() !== "5") {
        newNumber = "";
    }
    const comingNumber = newNumber.replace(/ /g, ""); // 555
    let comingOldNumber = oldNumber;

    if (isNaN(comingNumber)) {
        return oldNumber;
    }

    if (comingOldNumber === null) {
        comingOldNumber = "";
    }

    for (let i = 0; i < comingNumber.length; i++) {
        phoneNuber.push(comingNumber.charAt(i));
        if (i === 2 || i === 5 || i === 7) {
            if (newNumber.length > comingOldNumber.length) {
                phoneNuber.push(" ");
            } else if (i === comingNumber.length - 1) {
                phoneNuber.push("");
            } else {
                phoneNuber.push(" ");
            }
        }
    }

    const formattedNumber = phoneNuber.join("");

    return formattedNumber.slice(0, 13);
};

export default formatNumber;
