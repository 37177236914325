import axios from 'axios'
import {takeEvery,put,all,call,take} from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { showSmsReports } from './slice'
import { SmsReportTypes } from './types'


function* showSmsReportsHandler({page}) {
    try {
        const response = yield call(()=>axios.get(`${application.api}/sms/reports?page=${page}`))
        yield put(showSmsReports(response.data.data.Records))
    } catch (error) {
        toast.error(error.response.data.description)
    }
}

export function* SmsReportSagas() {
    yield all(
        [
            takeEvery(SmsReportTypes.SHOW_SMS_REPORTS,showSmsReportsHandler),
        ]
    )
}