import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    todos:[],
    allTodos:[]
}

const TodoListSlice = createSlice({
    name:'Todolist',
    initialState,
    reducers:{
        showTodoList:(state,action)=>{
            state.todos = action.payload
        },
        showAllTodoList:(state,action)=>{
            state.allTodos = action.payload
        },
        updateTodoList:(state,action)=>{
            const FindIndex = state.todos.findIndex(action.payload)
            state.todos[FindIndex] = {
                content:action.payload.content,
                date:action.payload.date,
                done:action.payload.done,
                id:action.payload.id,
                subject:action.payload.subject
            }
        },
        addTodo:(state,action)=>{
            state.todos.push(action.payload)
            state.allTodos.push(action.payload)
        },
        deleteTodo:(state,action)=>{
          state.todos = state.todos.filter(el=>el.id !== action.payload)
          state.allTodos = state.allTodos.filter(el=>el.id !== action.payload)
        },
        updateSingleTodo:(state,action)=>{
            const FindIndex = state.todos.findIndex(action.payload)
            state.todos[FindIndex] = {
                content:action.payload.content,
                date:action.payload.date,
                done:action.payload.done,
                id:action.payload.id,
                subject:action.payload.subject
            }
        }
    }
})

export const {showTodoList,deleteTodo,updateTodoList,addTodo,showAllTodoList,updateSingleTodo} = TodoListSlice.actions

export default TodoListSlice.reducer