import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    attendanceshess: []
}

const AttendanceSheeSlice = createSlice({
    name: 'AttendanceShee',
    initialState: initialState,
    reducers: {
        showAttendanceShee: (state, action) => {
            state.buildings = action.payload
        },
        addAttendanceShee: (state, action) => {
            state.buildings.push(action.payload)
        }
    }
})
export const { showAttendanceShee, addAttendanceShee } = AttendanceSheeSlice.actions
export default AttendanceSheeSlice.reducer