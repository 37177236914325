import axios from 'axios'
import { takeEvery, put, call, all } from 'redux-saga/effects'
import { BranchesTypes } from './types'
import toast from 'react-hot-toast'
import {
  showBranches,
  addBranches,
  deleteBranches,
  deleteSmsBranches,
  loginBranches,
} from './slice'
import { application } from '../../../../../redux/store'
import { numberReplace } from '../../../../../functions/Number/numberReplace'

function* showBranchesHandler({ page }) {
  try {
    const Branches = []
    const response = yield call(() =>
      axios.get(`${application.api}/branch?page=${page}`)
    )
    const responseUser = yield call(() => axios.get(`${application.api}/me`))
    Branches.push(...response.data.data)
    Branches.unshift(responseUser.data.data)
    yield put(showBranches(Branches))
  } catch (error) {}
}

function* addBranchesHandler({ payload }) {
  const Data = {
    assoc_name: payload.assoc_name,
    email: payload.email,
    name: payload.name,
    surname: payload.surname,
    password: payload.password,
    phone: numberReplace(payload.phone),
  }
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/branch`, Data)
    )
    yield put(addBranches(response.data.data))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteBranchesHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/branch`, {
        data: {
          branch_id: payload.branch_id,
          code: payload.branch_delete_code,
        },
      })
    )
    toast.success(response.data.description)
    yield showBranchesHandler(1)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* sendSmsBranchesDeleteHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/branch/request?branch_id=${payload.branch_id}`
      )
    )
    yield put(deleteSmsBranches(true))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* loginBranchesHandler({ payload, index }) {
  try {
    if (index !== 0) {
      const response = yield call(() =>
        axios.get(
          `${application.api}/login/branch?branch_id=${payload.branch_id}`
        )
      )
      localStorage.setItem('auth', response.data.data.token)
    }
    yield put(loginBranches(true))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
export function* BranchesSagas() {
  yield all([
    takeEvery(BranchesTypes.LOGIN_BRANCHES, loginBranchesHandler),
    takeEvery(BranchesTypes.SHOW_BRANCHES, showBranchesHandler),
    takeEvery(BranchesTypes.ADD_BRANCHES, addBranchesHandler),
    takeEvery(BranchesTypes.DELETE_BRANCHES, deleteBranchesHandler),
    takeEvery(BranchesTypes.DELETE_BRANCHES_SMS, sendSmsBranchesDeleteHandler),
  ])
}
