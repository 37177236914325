import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { EqualDebtTypes } from './types'
import { showApartment, addEqualDebt, filterLoad } from './slice'
import toast from 'react-hot-toast'
function* showEqualDebtHandler({ payload }) {
  try {
    const buildings =
      payload.id?.length > 0 ? payload.id?.map((item) => item.id) : null
    const response = yield call(() =>
      axios.post(`${application.api}/apartments/only/resident`, {
        buildings: buildings?.length > 0 ? buildings : null,
      })
    )
    yield put(showApartment(response.data.data))
    yield put(filterLoad(false))
  } catch (error) {
    yield put(showApartment(error.response.data.data))
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

function* addEqualDebtHandler({ payload }) {
  if (!payload.is_interest) {
    payload.interest = null
  }
  // girilmemiş amount  verileri backende gönderilmiyor
  payload.apartments = payload.apartments.filter(
    (el) => parseFloat(el.amount) > 0
  )
  payload.apartments = payload.apartments.map((item) => {
    return {
      apartment_id: item.apartment_id,
      amount: parseFloat(item.amount?.replaceAll(',', '.')),
      apartment_identifier: item.identifier,
      type: item.type,
      resident_id: item.resident_id,
      building_identifier: item.building_name,
    }
  })

  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/amount`, payload)
    )
    toast.success(response.data.description)
    yield put(addEqualDebt({ debts_error: response.data.data }))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
export function* EqualDebtSagas() {
  yield all([
    takeEvery(EqualDebtTypes.SHOW_APARTMENT_EQUAL_DEBT, showEqualDebtHandler),
    takeEvery(EqualDebtTypes.SHOW_APARTMENT_EQUAL_DEBT, filterLoadHandler),
    takeEvery(EqualDebtTypes.ADD_EQUAL_DEBT, addEqualDebtHandler),
  ])
}
