import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobs: [],
};
const SmsJobsSlice = createSlice({
  name: "SmsJobs",
  initialState: initialState,
  reducers: {
    showSmsJobs: (state, action) => {
      state.jobs = action.payload;
    },
    addSmsJobs: (state, action) => {
      state.jobs.push(action.payload);
    },
    updateSmsJobs: (state, action) => {
        const jobsIndex = state.jobs.findIndex(
          (el) => el.id === action.payload.id
        );
        state.jobs[jobsIndex] = action.payload;
      },
      deleteSmsJobs: (state, action) => {
        state.jobs = state.jobs.filter((el) => el.id !== action.payload.id);
      },
  },
});
export const { showSmsJobs, addSmsJobs,updateSmsJobs,deleteSmsJobs } = SmsJobsSlice.actions;
export default SmsJobsSlice.reducer;
