import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  residentDebts: [],
};

const ReportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    showResidentDebts: (state, action) => {
      state.residentDebts = action.payload;
    },
  },
});
export const { showResidentDebts } = ReportsSlice.actions;
export default ReportsSlice.reducer;
