import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dueGroups: [],
  account_types:[],
  aparmentsList:[]
};
const DefDueGroupSlice = createSlice({
  name: "dueGroupSlice",
  initialState: initialState,
  reducers: {
    showDueGroup: (state, action) => {
      state.dueGroups = action.payload;
    },
    addDueGroup: (state, action) => {
      state.dueGroups.push(action.payload);
    },
    updateDueGroup: (state, action) => {
      const updateIndex = state.dueGroups.findIndex(
        (el) => el.id === action.payload.id
      );
      state.dueGroups[updateIndex] = action.payload;
    },
    deleteDueGroup: (state, action) => {
      state.dueGroups = state.dueGroups.filter(
        (el) => el.id !== action.payload
      );
    },
    listTypes:(state,action)=>{
      state.account_types = action.payload
    },
    list_apartment:(state,action)=>{
      state.aparmentsList = action.payload
    }
  },
});
export const { showDueGroup, addDueGroup, updateDueGroup, deleteDueGroup,listTypes ,list_apartment} =
  DefDueGroupSlice.actions;
export default DefDueGroupSlice.reducer;
