import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    polls: [],
    pollDetails: {},
}
const PollSlice = createSlice({
    name: "pollSlice",
    initialState: initialState,
    reducers: {
        showPoll: (state, action) => {
            state.polls = action.payload
        },
        showPollDetails: (state, action) => {
            state.pollDetails = action.payload
        },
    }
})
export const { showPoll, showPollDetails } = PollSlice.actions

export default PollSlice.reducer