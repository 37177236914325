import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { CurrentAccountTypes } from './types'
import toast from 'react-hot-toast'
import {
  addCurrentAccount,
  showCurrentAccount,
  updateCurrentAccount,
  deleteCurrentAccount,
} from './slice'

function* showCurrentAccountHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/current/accounts?page=${page}`)
    )
    yield put(showCurrentAccount(response.data.data.Records))
  } catch (error) {}
}

function* addCurrentAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/current/account`, payload)
    )
    yield put(addCurrentAccount(response.data.data))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* updateCurrentAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/current/account`, {
        id: payload.values.id,
        name: payload.values.name,
        phone: payload.values.phone.toString(),
        address: payload.values.address,
      })
    )
    yield put(updateCurrentAccount(response.data.data))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteCurrentAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/current/account`, {
        data: {
          id: payload.id,
        },
      })
    )
    yield put(deleteCurrentAccount(payload.id))
    yield showCurrentAccountHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* CurrentAccountSagas() {
  yield all([
    takeEvery(
      CurrentAccountTypes.SHOW_CURRENT_ACCOUNT,
      showCurrentAccountHandler
    ),
    takeEvery(
      CurrentAccountTypes.ADD_CURRENT_ACCOUNT,
      addCurrentAccountHandler
    ),
    takeEvery(
      CurrentAccountTypes.UPDATE_CURRENT_ACCOUNT,
      updateCurrentAccountHandler
    ),
    takeEvery(
      CurrentAccountTypes.DELETE_CURRENT_ACCOUNT,
      deleteCurrentAccountHandler
    ),
  ])
}
