import axios from "axios";
import toast from "react-hot-toast";
import { application } from "../../../../../../redux/store";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { showAdvanceRecords,filterLoad } from "./slice";
import { AdvanceRecordsTypes } from "./types";

function* getAdvanceRecords({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(
        `${application.api}/advance/payment/report?page=${payload.page}`,
        {
          start_date: payload.data?.start_date,
          end_date: payload.data?.end_date,
          // resident_id: 0,
          // is_excel: true,
        }
      )
    );
    yield put(showAdvanceRecords(response.data.data.Records));
    if (response.data.data.Records?.length === 0) {
      toast.error("Listelenecek veri bulunamadı!")
    }
    yield put(filterLoad(false))
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

export function* AdvanceRecordsSagas() {
  yield all([
    takeLatest(AdvanceRecordsTypes.GET_ADVANCE_RECORDS, getAdvanceRecords),
    takeLatest(AdvanceRecordsTypes.GET_ADVANCE_RECORDS, filterLoadHandler),
  ]);
}
