import axios from 'axios'
import { takeEvery, put, call, all } from 'redux-saga/effects'
import { LoginTypes } from './types'
import { application } from '../../../../../redux/store'
import { numberReplace } from '../../../../../functions/Number/numberReplace'
import toast from 'react-hot-toast'
import {
  licenseErr,
  loginButton,
  loginError,
  loginSuccess,
  userInformation,
} from './slice'

function* loginHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/login`, {
        phone: numberReplace(payload.phone),
        password: payload.password,
      })
    )
    localStorage.setItem('auth', response.data.data.token)
    yield put(userInformation(response.data.data.parameters.user))
    yield put(loginSuccess())
    yield put(loginButton(true))
    toast.success('Giriş Başarıyla Tamamlandı')
  } catch (error) {
    if (
      error.response.data.description ===
      'Lisans süreniz dolmuştur. Lütfen lisans satın alınız. İletişim için :  0212-951-0676'
    ) {
      yield put(licenseErr(true))
    } else {
      toast.error(error.response.data.description)
    }
    yield put(loginError(error.response.status))
    yield put(loginButton(false))
  }
}
function* loginButtonHandler({}) {
  yield put(loginButton(true))
}
function* loginAdminHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/login/user/admin`, {
        phone: numberReplace(payload.phone),
        password: payload.password,
      })
    )
    localStorage.setItem('auth', response.data.data.token)
    yield put(userInformation(response.data.data.parameters.user))
    yield put(loginSuccess())
    toast.success('Giriş Başarıyla Tamamlandı')
  } catch (error) {
    toast.error(error.response.data.description)
    yield put(loginError())
  }
}

function* licenseErrorHandler({ payload }) {
  try {
    yield put(licenseErr(payload.status))
  } catch (error) {}
}

export function* LoginSagas() {
  yield all([takeEvery(LoginTypes.RQ_LOGIN, loginHandler)])
  yield all([takeEvery(LoginTypes.ADMIN_LOGIN, loginAdminHandler)])
  yield all([takeEvery(LoginTypes.LOGIN_BUTTON, loginButtonHandler)])
  yield all([takeEvery(LoginTypes.LICENSE_ERROR, licenseErrorHandler)])
}
